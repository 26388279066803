// Generated by Framer (045d2ed)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PU4V6DSmt", "of38CSvnT"];

const serializationHash = "framer-TWZcT"

const variantClassNames = {of38CSvnT: "framer-v-1u798l0", PU4V6DSmt: "framer-v-9vhalc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "PU4V6DSmt", "Variant 2": "of38CSvnT"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PU4V6DSmt"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PU4V6DSmt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter14nio1q = activeVariantCallback(async (...args) => {
setVariant("of38CSvnT")
})

const onMouseLeave8fa3nd = activeVariantCallback(async (...args) => {
setVariant("PU4V6DSmt")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-9vhalc", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"PU4V6DSmt"} onMouseEnter={onMouseEnter14nio1q} ref={ref ?? ref1} style={{"--border-bottom-width": "1.5px", "--border-color": "rgb(255, 255, 255)", "--border-left-width": "1.5px", "--border-right-width": "1.5px", "--border-style": "dashed", "--border-top-width": "1.5px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 25, borderBottomRightRadius: 25, borderTopLeftRadius: 25, borderTopRightRadius: 25, ...style}} variants={{of38CSvnT: {backgroundColor: "rgb(36, 218, 255)"}}} {...addPropertyOverrides({of38CSvnT: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave8fa3nd}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>CONTACT</motion.p></React.Fragment>} className={"framer-eq8c4r"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"Ra0RboIMy"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TWZcT.framer-30u4h6, .framer-TWZcT .framer-30u4h6 { display: block; }", ".framer-TWZcT.framer-9vhalc { height: 48px; overflow: hidden; position: relative; width: 114px; will-change: var(--framer-will-change-override, transform); }", ".framer-TWZcT .framer-eq8c4r { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-TWZcT[data-border=\"true\"]::after, .framer-TWZcT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 114
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"of38CSvnT":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRo3rmMHbG: React.ComponentType<Props> = withCSS(Component, css, "framer-TWZcT") as typeof Component;
export default FramerRo3rmMHbG;

FramerRo3rmMHbG.displayName = "HEADER LINK";

FramerRo3rmMHbG.defaultProps = {height: 48, width: 114};

addPropertyControls(FramerRo3rmMHbG, {variant: {options: ["PU4V6DSmt", "of38CSvnT"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerRo3rmMHbG, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-SemiBold.cyrillic-ext-C7KWUKA7.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-SemiBold.cyrillic-JWV7SOZ6.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-SemiBold.greek-ext-FBKSFTSU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-SemiBold.greek-EQ3PSENU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-SemiBold.latin-ext-ULRSO3ZR.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-SemiBold.latin-RDYY2AG2.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-SemiBold.vietnamese-ESQNSEQ3.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})